<template>
  <div>
    <b-card v-if="loading.gen">
      <b-spinner
        variant="primary"
        label="Text Centered"
        class="mr-2"
      ></b-spinner>
    </b-card>

    <b-card
      v-for="g in gruppi"
      :key="g.CieRegistrato"
      class="mb-4 cursor-pointer shadow-sm"
    >
      <!-- Intestazione della card con foto (se disponibile) -->
      <div class="w-100 flex justify-content-between align-items-center mb-5">
        <div class="flex align-items-center">
          <!-- Mostra l'immagine se esiste -->
          <img
            v-if="g.CieFoto"
            :src="g.CieFoto"
            alt="Foto di Cie"
            class="mr-3 img-thumbnail"
            style="width: 60px; height: 60px; object-fit: cover; border-radius: 50%;"
          />
          <div>
            <h5 class="mb-0">{{ g.CieRegistrato }}</h5>
            <small class="text-muted">Totale: {{ g.tempoTotale }}</small>
          </div>
        </div>
      </div>

      <!-- Corpo della card con la lista delle rilevazioni -->
      <h4>Rilevazioni</h4>
      <ul class="list-group list-group-flush">
        <li
          v-for="r in g.rilevazioni"
          :key="r.id"
          class="list-group-item px-0 py-2"
        >
          <div class="d-flex justify-content-start align-items-center">
            <!-- Foto Inizio -->
            <img
              v-if="r.fotoInizio"
              :src="'https://api-axerta.whappy.it' + r.fotoInizio"
              alt="Foto Inizio"
              class="mr-3"
              style="width: 50px; height: 50px; object-fit: cover; border-radius: 4px;"
            />
            <img
              v-else
              src="https://placehold.co/50"
              alt="Foto Inizio"
              class="mr-3"
              style="width: 50px; height: 50px; object-fit: cover; border-radius: 4px;"
            />

            <div class="mr-4">
              <span class="font-weight-bold">Inizio:</span>
              {{
                r.dataOraInizio ? formatDate(r.dataOraInizio) : "Data mancante"
              }}
            </div>

            <!-- Foto Fine -->
            <img
              v-if="r.fotoFine"
              :src="'https://api-axerta.whappy.it' + r.fotoFine"
              alt="Foto Fine"
              class="mr-3"
              style="width: 50px; height: 50px; object-fit: cover; border-radius: 4px;"
            />
            <img
              v-else
              src="https://placehold.co/50"
              alt="Foto Fine"
              class="mr-3"
              style="width: 50px; height: 50px; object-fit: cover; border-radius: 4px;"
            />

            <div>
              <span class="font-weight-bold">Fine:</span>
              {{ r.dataOraFine ? formatDate(r.dataOraFine) : "Data mancante" }}
            </div>

            <div class="ml-auto flex flex-column justify-content-center">
              <b-form-input
                v-model="r.tempoTotale"
                @input="aggiornaTempoTotale(g)"
              />

              <span v-if="r.dataVersamentoGamma">
                Data versamento Gamma: {{ formatDate(r.dataVersamentoGamma) }}
              </span>

              <b-button
                v-else
                :disabled="r.loading"
                @click="inviaTempo(g, r)"
                variant="primary"
                class="mt-3"
              >
                {{ r.loading ? "Loading..." : "Invia" }}
              </b-button>
            </div>
          </div>
        </li>
      </ul>
    </b-card>
  </div>
</template>

<script>
import AxiosService from "@/axiosServices/AxiosService.js";
export default {
  name: "Versamenti",
  props: {
    dossier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        gen: false,
        ordiniDiServizio: false,
        rilevazioni: false,
      },
      ordiniDiServizio: [],
      rilevazioni: [],
      gruppi: {},
    };
  },
  async mounted() {
    this.ordiniDiServizio.splice(0);
    this.rilevazioni.splice(0);
    this.gruppi = {};

    await this.setupView();
    this.gruppi = this.raggruppaRilevazioniPerCie();
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString("it", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    validaTempo(tempo) {
      // Verifica se il formato è corretto (hh:mm)
      const regex = /^([0-1]\d|2[0-3]):([0-5]\d)$/;
      if (!regex.test(tempo)) {
        return "00:00"; // Ritorna "00:00" se non valido o vuoto
      }
      return tempo;
    },
    aggiornaTempoTotale(gruppo) {
      // Calcola il tempo totale per il gruppo
      let tempoTotaleGruppo = 0;

      gruppo.rilevazioni.forEach((r) => {
        const tempo = this.validaTempo(r.tempoTotale);
        const [ore, minuti] = tempo.split(":").map(Number);
        tempoTotaleGruppo += ore * 3600 + minuti * 60;
      });

      // Ricalcola il tempo totale del gruppo in "HH:mm"
      const oreGruppo = Math.floor(tempoTotaleGruppo / 3600);
      const minutiGruppo = Math.floor((tempoTotaleGruppo % 3600) / 60);
      gruppo.tempoTotale = `${String(oreGruppo).padStart(2, "0")}:${String(
        minutiGruppo
      ).padStart(2, "0")}`;
    },

    raggruppaRilevazioniPerCie() {
      const gruppi = {};

      this.rilevazioni.forEach((rilevazione) => {
        // Controlla se dataOraInizio e dataOraFine esistono prima di calcolare il tempo
        if (rilevazione.dataOraInizio && rilevazione.dataOraFine) {
          const inizio = new Date(rilevazione.dataOraInizio);
          const fine = new Date(rilevazione.dataOraFine);
          const tempoTotale = (fine - inizio) / 1000; // tempo in secondi

          // Calcolo delle ore e dei minuti dal tempo totale in secondi
          const ore = Math.floor(tempoTotale / 3600);
          const minuti = Math.floor((tempoTotale % 3600) / 60);

          // Formatta il tempo come "HH:mm"
          const tempoTotaleFormattato = `${String(ore).padStart(
            2,
            "0"
          )}:${String(minuti).padStart(2, "0")}`;

          // Aggiungi il campo "tempoTotale" alla rilevazione
          rilevazione.tempoTotale = tempoTotaleFormattato;

          // Raggruppa per CieRegistrato
          if (!gruppi[rilevazione.CieRegistrato]) {
            gruppi[rilevazione.CieRegistrato] = {
              CieRegistrato: rilevazione.CieRegistrato,
              CieFoto: rilevazione.CieFoto,
              rilevazioni: [],
              tempoTotale: "00:00", // valore iniziale
            };
          }

          // Aggiungi la rilevazione al gruppo
          gruppi[rilevazione.CieRegistrato].rilevazioni.push(rilevazione);

          // Calcola il nuovo tempo totale del gruppo in secondi
          const tempoTotaleGruppo =
            gruppi[rilevazione.CieRegistrato].tempoTotale === "00:00"
              ? tempoTotale
              : parseInt(
                  gruppi[rilevazione.CieRegistrato].tempoTotale.split(":")[0]
                ) *
                  3600 +
                parseInt(
                  gruppi[rilevazione.CieRegistrato].tempoTotale.split(":")[1]
                ) *
                  60 +
                tempoTotale;

          // Ricalcola il tempo totale del gruppo in "HH:mm"
          const oreGruppo = Math.floor(tempoTotaleGruppo / 3600);
          const minutiGruppo = Math.floor((tempoTotaleGruppo % 3600) / 60);
          gruppi[rilevazione.CieRegistrato].tempoTotale = `${String(
            oreGruppo
          ).padStart(2, "0")}:${String(minutiGruppo).padStart(2, "0")}`;
        } else {
          // Se una delle date è null, assegna "00:00" come tempo totale per quella rilevazione
          rilevazione.tempoTotale = "00:00";

          // Raggruppa comunque la rilevazione nel gruppo
          if (!gruppi[rilevazione.CieRegistrato]) {
            gruppi[rilevazione.CieRegistrato] = {
              CieRegistrato: rilevazione.CieRegistrato,
              CieFoto: rilevazione.CieFoto,
              rilevazioni: [],
              tempoTotale: "00:00", // valore iniziale
            };
          }

          gruppi[rilevazione.CieRegistrato].rilevazioni.push({
            ...rilevazione,
            loading: false,
          });
        }
      });

      return gruppi;
    },
    async inviaTempo(g, r) {
      const service = new AxiosService(
        "DossierManager/VersamentoGamma/" + r.id
      );

      const payload = {
        idProgetto: this.dossier.codice,
        idFornitore: g.codiceFornitoreGamma,
        idNodo: "ODI",
        data: new Date(),
        numeroOre: r.tempoTotale,
      };

      try {
        r.loading = true;
        await service.create(payload);
      } catch (err) {
        console.log(err);
      } finally {
        r.loading = false;
        await this.setupView();
        this.gruppi = this.raggruppaRilevazioniPerCie();
      }
    },
    async setupView() {
      this.loading.gen = true;

      this.ordiniDiServizio.splice(0);
      this.rilevazioni.splice(0);
      this.gruppi = {};

      this.ordiniDiServizio = await this.getOrdiniDiServizio();

      for (let o of this.ordiniDiServizio) {
        let rilevazioni = await this.getRilevazioni(o.id);
        for (let r of rilevazioni) {
          this.rilevazioni.push(r);
        }
      }

      this.loading.gen = false;
    },
    async getOrdiniDiServizio() {
      this.loading.ordiniDiServizio = true;

      const service = new AxiosService(
        "DossierManager/GetOrdiniDiServizio/" + this.dossier.id
      );

      try {
        const res = await service.read();
        return res;
      } catch (err) {
        console.log(err);
        return err;
      } finally {
        this.loading.ordiniDiServizio = false;
      }
    },
    async getRilevazioni(idOrdineDiServizio) {
      this.loading.rilevazioni = true;

      const service = new AxiosService(
        "DossierManager/GetRilevazioni/" + idOrdineDiServizio
      );

      try {
        const res = await service.read();
        return res;
      } catch (err) {
        console.log(err);
        return err;
      } finally {
        this.loading.rilevazioni = false;
      }
    },
  },
};
</script>
